<template>
  <div class="form">
    <el-form :model="form" ref="form" label-width="150rem" style="width: calc(100% - 300rem);margin-left:  100rem;
    margin-bottom: 100rem;">
      <el-form-item label="考试类型名称">
        <div class="data">{{form.name}}</div>
      </el-form-item>
      <el-form-item label="考试范围">
        <div class="data">{{form.scopeName}}</div>
      </el-form-item>
      <el-form-item label="适用校区" >
        <div class="data" v-for="item in form.school_ids">
          {{item}}
        </div>
      </el-form-item>
      <el-form-item label="备注">
        <div class="data">{{form.note}}</div>
      </el-form-item>
    </el-form>
    <div class="footer-button">
      <el-button type="primary" @click="$router.back();">返回</el-button>
    </div>
  </div>
</template>

<script>

export default {
	_config:{"route":{"path":"show","meta":{"title":"查看"}}},
  data(){
    return{
      form:{
        name:null,
        scope:null,
        school_ids:[],
        note:null,
      },
      schoolList:[],
      allSchool:false,
      id:null,
    }
  },
  methods:{
    // 初始化
    async init(){
      // 获取校区列表
      await this.$utils.commonApi.getSchoolList().then(res=>{
        let toArray = this.$tools.jsonToArray(res);
        let len = toArray.length;
        this.schoolList = toArray;
      })
      // 获取初始数据
      this.$_axios.get("/exam-type/"+this.id).then(res=>{
        let {data} = res.data;
        data.school_ids = data.school_ids.map(item => {

          for (let i = 0; i < this.schoolList.length; i++) {
            let _item = this.schoolList[i];
            if (_item.key === item) {
              let val = _item.val;
              this.schoolList.splice(i, 1);
              return val;
            }
          }
        });
        this.allSchool = data.school_ids.length === this.schoolList.length
        data.scope += "";
        this.form = data;
      })
    },
    changeAll(e){
      this.form.school_ids = e?this.schoolList.map(res=>res.key):[];
    },
    changeCheckbox(e){
      this.allSchool = e.length === this.schoolList.length
    }
  },
  mounted() {
    this.id = this.$route.query.id;
    this.init();
  }
}
</script>

<style scoped>

.form{
  padding-top: 100rem;
}
::v-deep .el-form .el-input{
  min-width: 250rem;
}
</style>
